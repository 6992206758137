<template>
  <div v-if="question" class="">
  </div>
</template>

<script>
import base from '../base';
export default {
  name: 'NameEdit',
  mixins: [base],
};
</script>
